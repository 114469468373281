<template>
  <div>
    <div class="recipes">
      <div class="container">
        <div class="banner"></div>
        <div class="recipes__info">
          <h1>{{ $t("recipes.title") }}</h1>
          <p>{{ $t("recipes.desc") }}</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="steps">
        <div>
          {{ $t("recipes.textHeading") }}
          <ol class="steps__list">
            <li>{{ $t("recipes.text1") }}</li>
            <li>{{ $t("recipes.text2") }}</li>
            <li>{{ $t("recipes.text3") }}</li>
            <li>{{ $t("recipes.text4") }}</li>
          </ol>
        </div>
        <div class="structure_line">
          <div class="structure_container item-1 left">
            <div class="content" v-html="$t('recipes.step1')"></div>
          </div>
          <div class="structure_container item-2 right">
            <div class="content" v-html="$t('recipes.step2')"></div>
          </div>
          <div class="structure_container item-3 left">
            <div class="content" v-html="$t('recipes.step3')"></div>
          </div>
          <div class="structure_container item-4 right">
            <div class="content" v-html="$t('recipes.step4')"></div>
          </div>
          <div class="structure_container item-5 left">
            <div class="content" v-html="$t('recipes.step5')"></div>
          </div>
        </div>
        <p class="steps__description" v-html="$t('recipes.stepsDesc')"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.steps {
  padding: 30px 0 50px 0;
  font-size: 20px;
  line-height: 23px;
  color: #202020;
  &__description {
    text-align: center;
    a {
      text-decoration: none;
      font-weight: bold;
      color: #202020;
    }
  }
  &__list {
    margin-top: 30px;
    padding-left: 20px;
    li {
      margin: 10px 0;
    }
  }
}
.recipes {
  background-color: #ffffff;
  padding: 60px 0 20px 0;
  &__info {
    color: #202020;
    h1 {
      font-weight: 500;
      font-size: 30px;
      line-height: 35px;
    }
    p {
      margin-top: 10px;
      font-size: 20px;
      line-height: 23px;
    }
  }
}
.banner {
  background-size: contain;
  background-image: url("../assets/images/recipes-banner.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 540px;
  margin: 0 auto;
}
.structure_line {
  position: relative;
  max-width: 1200px;
  margin: 30px auto;
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    background-color: #00303a;
    top: 30px;
    bottom: 60px;
    left: 50%;
  }
}
.structure_container {
  padding: 60px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}
.item-1::after {
  content: "1";
}
.item-2::after {
  content: "2";
}
.item-3::after {
  content: "3";
}
.item-4::after {
  content: "4";
}
.item-5::after {
  content: "5";
}
.structure_container::after {
  position: absolute;
  width: 80px;
  font-weight: 500;
  font-size: 45px;
  line-height: 53px;
  color: #e66e00;
  height: 80px;
  right: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00303a;
  top: 30px;
  border-radius: 50%;
  z-index: 1;
}
.left {
  left: 0;
  text-align: right;
}
.right {
  left: 50%;
}
.right::after {
  left: -40px;
}
.content {
  padding: 0 30px;
  font-size: 20px;
  line-height: 23px;
  color: #202020;
  position: relative;
}
@media (max-width: 768px) {
  .steps {
    font-size: 18px;
  }
  .recipes__info {
    h1 {
      font-size: 25px;
    }
    p {
      font-size: 18px;
    }
  }
  .banner {
    height: 380px;
  }
  .content {
    font-size: 18px;
    line-height: 20px;
    padding-right: 0;
  }
  .structure {
    &__title {
      font-size: 18px;
      line-height: 18px;
    }
  }
  .structure_line::after {
    left: 31px;
  }

  .structure_container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  .left::after,
  .right::after {
    left: -10px;
  }
  .right {
    left: 0%;
  }
  .left {
    text-align: left;
  }
}
@media (max-width: 480px) {
  .left {
    &::after {
      left: 5px;
    }
  }
  .structure_container {
    padding: 40px;
    &::after {
      height: 50px;
      width: 50px;
      left: 5px;
      font-size: 25px;
    }
  }
  .banner {
    height: 290px;
  }
}
</style>
